@keyframes move {
  0% { top: -40px; }
  100% { top: 0; }
}

body {
  background-color: #316A85;
  font-family: sans-serif;
  margin: 0;
}

header {

  h1 {
    background-color: #ABC944;
    border-bottom: 2px solid #316A85;
    color: #316A85;
    cursor: pointer;
    margin: 0;
    padding: 10px 0;
    text-align: center;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
}

main {
  display: flex;
  justify-content: space-evenly;
}

.column {
  align-items: center;
  background-color: #39A939;
  border: 2px solid #39A939;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  height: 50vh;
  justify-content: flex-end;
  max-width: 250px;
  transition: border-color 0.3s;
  width: 30%;
  -webkit-tap-highlight-color: transparent;

  @media (min-width: 800px) {
    &:hover {
      border-color: #ABC944;
    }
  }

  &.selected {
    border-color: #ABC944;
  }

  > div {
    align-items: center;
    animation: move 0.5s ease-out;
    background-color: #ABC944;
    border: 2px solid #316A85;
    color: #316A85;
    display: flex;
    height: 8vh;
    justify-content: center;
    margin-bottom: 10px;
    position: relative;
    user-select: none;
  }

  .one {
    width: 30%;
  }

  .two {
    width: 50%;
  }

  .three {
    width: 70%;
  }

  .four {
    width: 90%;
  }
}

.popup.on {
  align-items: center;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  .text {
    background-color: #ABC944;
    border: 2px solid #316A85;
    box-sizing: border-box;
    color: #316A85;
    cursor: pointer;
    font-size: 22px;
    max-width: 450px;
    padding: 15px 25px;
    text-align: center;
    width: calc(95% + 6px);
  }
}

.infoBox {
  animation: move 0.5s ease-out;
  background-color: #ABC944;
  color: #316A85;
  display: table;
  font-size: 20px;
  margin: 10px auto 0;
  padding: 5px 20px;
  position: relative;
}

.wrongMove.off {
  display: none;
}
